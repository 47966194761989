<template>
  <div class="feedback">
    <div class="container py-5 py-lg-6">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-6">
          <!-- 點數總計 -->
          <div class="bg-white d-flex justify-content-between mb-3 mb-lg-4 py-3">
            <div class="w-50 text-center border-end border-dark">
              <p>總點數</p>
              <p class="fs-3">{{ $currency.currency(totalPoints) || 0 }} 點</p>
            </div>
            <div class="w-50 text-center">
              <p>{{ expiringDate }} 到期點數</p>
              <p class="fs-3">{{ $currency.currency(expiringPoints &gt; 0 ? expiringPoints : 0) }} 點</p>
            </div>
          </div>
          <!-- 點數歷程 -->
          <table v-if="pointList" class="table p-3">
            <thead class="bg-secondary text-center text-white text-nowrap">
              <tr>
                <th width="20%" class="pb-1">歸戶日期</th>
                <th class="pb-1">類別</th>
                <th width="20%" class="pb-1">點數</th>
              </tr>
            </thead>
            <tbody class="bg-white text-center">
              <tr v-for="point in pointList" :key="point" class="border-bottom border-dark">
                <td class="py-3" v-if="point.createDate">{{ point.createDate }}</td>
                <td class="py-3">{{ point.description }}</td>
                <td class="py-3">{{ point.rewardPoint }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      pointList: '',
      totalPoints: 0,
      expiringPoints: 0,
      expiringDate: ''
    }
  },
  methods: {
    getPointList () {
      const url = `${process.env.VUE_APP_API}/api/members/rewardpointrecords`
      this.$http.get(url)
        .then((res) => {
          if (res.data.rtnCode !== 0) {
            this.$swal.fire({
              title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
              allowOutsideClick: true,
              confirmButtonColor: '#003894',
              confirmButtonText: '確認',
              width: 400,
              customClass: {
                title: 'text-class',
                confirmButton: 'confirm-btn-class'
              }
            })
            return ''
          }
          this.pointList = res.data.info.smoneys

          this.getRecentDuePoints()
          //* 點數排序(依歸戶日期)
          this.pointList.sort((a, b) => {
            return Date.parse(b.createDate) - Date.parse(a.createDate)
          })
          this.pointList.forEach((item) => {
            //* 日期格式
            item.createDate = item.createDate.toString().replace(/-/g, '/').split('T')[0]
            //* 總點數
            this.totalPoints += item.rewardPoint
          })
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.getPointList()
          }
        })
    },
    getRecentDuePoints () {
      //* 取出正且有到期日的回饋點數，依到期日排序
      let list = []
      list = this.pointList.filter((item) => item.dueDate && item.rewardPoint > 0)
      list.sort((a, b) => {
        return moment(a.dueDate, 'YYYY-MM-DD') - moment(b.dueDate, 'YYYY-MM-DD')
      })

      //* 計算最近到期日
      let idx = ''
      const now = moment().format('YYYY-MM-DD')
      idx = list.findIndex((item) => {
        return moment(now, 'YYYY-MM-DD').isBefore(item.dueDate)
      })
      this.expiringDate = list[idx].dueDate.toString().replace(/-/g, '.')
      //* 加總最近到期總點數(正)
      for (let i = 0; i < idx + 1; i++) {
        this.expiringPoints += list[i].rewardPoint
      }
      for (let j = idx + 1; j < list.length && list[j].dueDate === list[idx].dueDate; j++) {
        this.expiringPoints += list[j].rewardPoint
      }
      //* 扣除所有為負的回饋點數
      let minusPoints = []
      minusPoints = this.pointList.filter((item) => item.rewardPoint < 0)
      minusPoints.forEach((item) => {
        this.expiringPoints += item.rewardPoint
      })
      //* 點數排序(依到期日)
      // let list = []
      // list = this.pointList.filter((item) => item.dueDate)
      // list.sort((a, b) => {
      //   return moment(a.dueDate, 'YYYY-MM-DD') - moment(b.dueDate, 'YYYY-MM-DD')
      // })
      // console.log(list)
      // //* 計算最近到期總點數
      // let idx = ''
      // const now = moment().format('YYYY-MM-DD')
      // idx = list.findIndex((item) => {
      //   return moment(now, 'YYYY-MM-DD').isBefore(item.dueDate)
      // })
      // this.expiringDate = list[idx].dueDate.toString().replace(/-/g, '.')
      // for (let i = 0; i < idx + 1; i++) {
      //   this.expiringPoints += list[i].rewardPoint
      // }

      // //* 扣除無 dueDate 但為負的回饋點數(ex. 活動折抵 or 年度結清)
      // let noDuelist = []
      // noDuelist = this.pointList.filter((item) => !item.dueDate && item.rewardPoint < 0)
      // noDuelist.forEach((item) => {
      //   this.expiringPoints += item.rewardPoint
      // })
      // console.log(noDuelist)

      // //* 扣除 dueDate 還沒到期但為負的回饋點數
      // for (let i = idx + 1; i < list.length - 1; i++) {
      //   if (list[i].rewardPoint < 0) {
      //     this.expiringPoints += list[i].rewardPoint
      //   }
      // }
    }
  },
  mounted () {
    this.getPointList()
  }
}
</script>

<style lang="scss" scoped>
.feedback{
  background-color: #f0f0f2;
  min-height: calc(100vh - 404px);
  @media (min-width: 576px) {
    min-height: calc(100vh - 414px);
  }
  @media (min-width: 768px) {
    min-height: calc(100vh - 488px);
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 465px);
  }
  @media (min-width: 1200px) {
    min-height: calc(100vh - 459px);
  }
}

table tbody tr:last-child{
  border-bottom: #fff !important;
}

table {
  border-collapse: collapse;
}

table tbody {
  border-top: 8px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 0px solid transparent;
  border-left: 16px solid transparent;
}
</style>
